import React from 'react';
import PropTypes from 'prop-types';
import classNamesHelper from 'bbc-morph-classnames';

const GsSectionTag = ({ item, onDark, classNames }) => {
  const classes = [
    'gs-u-align-middle',
    'gs-o-section-tag',
    'gel-brevier',
    'qa-section-tag',
    ...classNames,
  ];

  if (item.sectionUrl === '/news') {
    classes.push('gs-o-section-tag--news');
  }
  if (onDark) {
    classes.push('gs-o-section-tag--on-dark');
  }

  return (
    <span className={classNamesHelper(classes)}>
      <span className="gs-u-vh">From the section </span>
      <a href={item.sectionUrl}>{item.sectionName}</a>
    </span>
  );
};

GsSectionTag.displayName = 'GsSectionTag';

GsSectionTag.propTypes = {
  item: PropTypes.shape({
    sectionName: PropTypes.string,
    sectionUrl: PropTypes.string,
  }).isRequired,
  onDark: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

GsSectionTag.defaultProps = {
  onDark: false,
  classNames: [],
};

export default GsSectionTag;
